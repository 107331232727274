Ember.TEMPLATES["javascripts/connectors/discovery-list-container-top/admin-search"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[1,[28,\"mount-widget\",null,[[\"widget\"],[\"admin-search\"]]],false],[0,\"\\n\"]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/connectors/discovery-list-container-top/admin-search"}});
define("discourse/plugins/admin-search/discourse/widgets/admin-menu-panel", ["discourse/widgets/widget", "virtual-dom", "discourse/lib/url"], function (_widget, _virtualDom, _url) {
  "use strict";

  var isStaff = false;
  var staff = {};
  var isShowing = false;
  (0, _widget.createWidget)('admin-menu-panel', {
    tagName: 'div.admin-menu-panel',
    buildKey: function buildKey(attrs) {
      return "admin-search-options";
    },
    defaultState: function defaultState(attrs) {
      return {
        filters: {
          solved: false,
          unsolved: false,
          assigned: false,
          unassigned: false,
          sort: "ascending"
        }
      };
    },
    html: function html(attrs) {
      var results = [];
      var options = [];
      this.saveQuery("", false); //Define if this is an admin type search or user based search.

      if (attrs.isStaff) {
        options.push((0, _virtualDom.h)('label.admin-sort-label', 'Administrative Search'), (0, _virtualDom.h)('li.grow#assigned', [(0, _virtualDom.h)('i.fa.fa-user-plus.option-icon'), 'Assigned', (0, _virtualDom.h)('i.fa.fa-check.admin-options-icons')]), (0, _virtualDom.h)('li.grow#unassigned', [(0, _virtualDom.h)('i.fa.fa-user-times.option-icon'), 'Unassigned', (0, _virtualDom.h)('i.fa.fa-check.admin-options-icons')]));
      } else {
        options.push((0, _virtualDom.h)('label.admin-sort-label', 'User Search'));
      }

      options.push([(0, _virtualDom.h)('li.grow#solved', [(0, _virtualDom.h)('i.fa.fa-check.option-icon'), 'Solved', (0, _virtualDom.h)('i.fa.fa-check.admin-options-icons')]), (0, _virtualDom.h)('li.grow#unsolved', [(0, _virtualDom.h)('i.fa.fa-times.option-icon'), 'Unsolved', (0, _virtualDom.h)('i.fa.fa-check.admin-options-icons')]), (0, _virtualDom.h)('li.grow#unanswered', [(0, _virtualDom.h)('i.fa.fa-question.option-icon'), 'Unanswered', (0, _virtualDom.h)('i.fa.fa-check.admin-options-icons')]), (0, _virtualDom.h)('li.grow#all', [(0, _virtualDom.h)('i.fa.fa-globe.option-icon'), 'all', (0, _virtualDom.h)('i.fa.fa-check.admin-options-icons')]), (0, _virtualDom.h)('label.admin-sort-label', {
        for: 'admin-sort'
      }, 'Sort Date By:'), (0, _virtualDom.h)('button.btn.btn-primary#updateFilters', 'Update Filters'), (0, _virtualDom.h)('label.admin-sort-label', 'My Saved Searches'), (0, _virtualDom.h)('div.row', [(0, _virtualDom.h)('input.column-med#searchName', {
        placeholder: 'Enter a name for your search.. '
      }), (0, _virtualDom.h)('button.column-small.btn.btn-primary#saveSearchQuery', 'Save Search')])]);
      var savedSearches = this.retrieveSavedQueries();

      if (savedSearches.length > 1) {
        savedSearches.sort(function (a, b) {
          return a["params"].totalClicks < b["params"].totalClicks;
        });
      }

      var savedSearchArr = [];

      for (var i = 0; i < savedSearches.length; i++) {
        savedSearchArr.push((0, _virtualDom.h)('li#savedQuery', {
          title: savedSearches[i].queryName
        }, (0, _virtualDom.h)('span#savedQuery', {
          title: savedSearches[i].queryName
        }, [savedSearches[i].queryName, (0, _virtualDom.h)("i.fa.fa-times#deleteKey", {
          title: savedSearches[i].queryName
        })])));
      } //Display saved queries at the very bottom.


      options.push((0, _virtualDom.h)('div.savedSearches', (0, _virtualDom.h)('ul.admin-options-popup.mySaved', savedSearchArr)));
      results.push((0, _virtualDom.h)('div.search-menu-panel', [(0, _virtualDom.h)('ul.admin-options-popup', options)]));
      return results;
    },
    click: function click(e) {
      if (e.target.id === 'updateFilters') {
        $('#admin-menu-panel').hide();
        this.scheduleRerender();
        this.sendWidgetAction('redirectSearch');
        return;
      }

      if (e.target.id === 'saveSearchQuery') {
        if ($('#searchName').val().length > 0) {
          var value = $('#searchName').val();
          this.saveQuery(value, true);
          return;
        } else {
          alert('Cannot save empty search');
        }

        return;
      }

      if (e.target.id === 'deleteKey') {
        this.deleteSavedSearch(e.target.title);
        return;
      }

      if (e.target.id === 'searchSave' || e.target.id === 'searchName') {
        return;
      }

      if (e.target.id === 'savedQuery') {
        this.updateQueryClicks(e.target.title);
        return;
      }

      if ($(e.target).is('select')) {
        return;
      }

      var targetId = "";
      targetId = e.target.id;
      this.state.filters[targetId] = this.state.filters[targetId] ? false : true;

      if (targetId === "all") {
        var updateVal = false;

        if (this.state.filters[targetId]) {
          $('.admin-options-icons').show();
          updateVal = true;
        } else {
          $('.admin-options-icons').hide();
          updateVal = false;
        }

        for (var key in this.state.filters) {
          if (this.state.filters.hasOwnProperty(key)) {
            this.state.filters[key] = updateVal;
          }
        }

        this.sendWidgetAction('updateSearchFilters', this.state.filters);
        this.scheduleRerender();
        return;
      }

      if (this.state.filters[targetId]) {
        $('#' + targetId + ' .admin-options-icons').show();
      } else {
        $('#' + targetId + ' .admin-options-icons').hide();
      }

      this.sendWidgetAction('updateSearchFilters', this.state.filters);
      this.scheduleRerender();
    },
    updateOptionsUser: function updateOptionsUser(attrs) {
      this.state.staff = attrs.staff;
      this.scheduleRerender();
    },
    saveQuery: function saveQuery(_saveQuery, hasRun) {
      var queries = JSON.parse(localStorage.getItem('admin_stored_searches'));

      if (!queries) {
        queries = [];
        queries.push({
          queryName: "My Assigned (Unsolved)",
          params: {
            query: '/latest?assigned=me&solved=no',
            totalClicks: 0
          }
        }, {
          queryName: "Unanswered Topics",
          params: {
            query: '/latest?assigned=&solve=&max_posts=1',
            totalClicks: 0
          }
        });
        localStorage.setItem('admin_stored_searches', JSON.stringify(queries));
        this.scheduleRerender();
        return;
      }

      var keyExists = false,
          valueExists = false;

      for (var i = 0; i < queries.length; i++) {
        if (queries[i].queryName === _saveQuery) {
          keyExists = true;
        }

        if (queries[i].params.query === window.location.href) {
          valueExists = true;
        }
      }

      if (!keyExists && !valueExists && hasRun) {
        queries.push({
          queryName: _saveQuery,
          params: {
            query: '/latest?assigned=&solve=&max_posts=1',
            totalClicks: 0
          }
        });
        localStorage.setItem('admin_stored_searches', JSON.stringify(queries));
        this.scheduleRerender();
      }
    },
    retrieveSavedQueries: function retrieveSavedQueries() {
      var queries = JSON.parse(localStorage.getItem('admin_stored_searches'));

      if (!queries) {
        queries = {};
      }

      return queries;
    },
    deleteSavedSearch: function deleteSavedSearch(name) {
      var queries = JSON.parse(localStorage.getItem('admin_stored_searches'));

      for (var i = 0; i < queries.length; i++) {
        if (queries[i].queryName === name) {
          queries.splice(i, 1);
          localStorage.setItem('admin_stored_searches', JSON.stringify(queries));
        }
      }

      this.scheduleRerender();
    },
    updateQueryClicks: function updateQueryClicks(queryName) {
      var queries = JSON.parse(localStorage.getItem('admin_stored_searches'));

      for (var i = 0; i < queries.length; i++) {
        if (queries[i].queryName === queryName) {
          queries[i].params.totalClicks++;
          localStorage.setItem('admin_stored_searches', JSON.stringify(queries));

          _url.default.routeTo(queries[i].params.query);
        }
      }

      this.scheduleRerender();
    }
  });
});
define("discourse/plugins/admin-search/discourse/widgets/admin-search-result-menu", ["discourse/widgets/widget", "virtual-dom", "discourse/lib/utilities", "discourse/widgets/post", "discourse/helpers/node", "discourse/lib/ajax", "discourse/lib/url"], function (_widget, _virtualDom, _utilities, _post, _node, _ajax, _url) {
  "use strict";

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  (0, _widget.createWidget)('admin-search-results', {
    buildClasses: function buildClasses(attrs) {
      if (attrs.name && attrs.name.length) {
        return "admin-search-results-".concat(attrs.name);
      }
    },
    html: function html(attrs) {
      var results = [];

      if (attrs.searching) {
        results.push((0, _virtualDom.h)('div.searching', (0, _virtualDom.h)('div.spinner')));
      }

      if (attrs.invalidSearch) {
        results.push((0, _virtualDom.h)('div.menu-panel.admin-search-panel', [(0, _virtualDom.h)('b.admin-error', 'Search query too short.')]));
      }

      if (attrs.hasRun && (typeof attrs.topics === 'undefined' || attrs.topics.length === 0) && (_typeof(attrs.users) === undefined || attrs.users.length === 0) && !attrs.invalidSearch) {
        results.push((0, _virtualDom.h)('div.menu-panel.admin-search-panel', [(0, _virtualDom.h)('b.admin-error', 'No results could be found for your search.')]));
      }

      var userResults = [];

      if (attrs.users.length > 0 && !attrs.searching && !attrs.invalidSearch) {
        for (var i = 0; i < attrs.users.length; i++) {
          var userRights = "";

          if (attrs.users[i].admin || attrs.users[i].moderator) {
            userRights = "Staff Member";
          } else {
            userRights = "Customer/Distributor";
          }

          userResults.push((0, _virtualDom.h)('li#admin-search-user', {
            title: attrs.users[i].username
          }, [(0, _post.avatarImg)('small', {
            id: 'admin-search-img',
            template: attrs.users[i].avatar_template,
            username: attrs.users[i].username
          }), (0, _virtualDom.h)('b', (0, _utilities.formatUsername)(attrs.users[i].username)), (0, _virtualDom.h)('i', " (".concat(attrs.users[i].email, ")")), '  -  ', (0, _virtualDom.h)('span', userRights)]));
        }
      }

      var topicResults = [];

      if (typeof attrs.topics !== 'undefined' && attrs.topics.length > 0 && !attrs.searching && !attrs.invalidSearch) {
        for (var i = 0; i < attrs.topics.length; i++) {
          var postBlurb = "";
          var createdAt = "";

          if (typeof attrs.posts[i].blurb === 'undefined') {
            if (attrs.posts[i][0].cooked) {
              var hiddenDiv = document.createElement('div');
              hiddenDiv.innerHTML = attrs.posts[i][0].cooked;
              postBlurb = hiddenDiv.text || hiddenDiv.innerText;
              postBlurb = postBlurb.substring(0, 200);
              createdAt = attrs.posts[i][0].created_at;
            }
          } else {
            postBlurb = attrs.posts[i].blurb;
            createdAt = attrs.posts[i].created_at;
          }

          topicResults.push((0, _virtualDom.h)('li.admin-topic#topic-search-row', {
            title: attrs.topics[i].id
          }, (0, _virtualDom.h)('span', [(0, _virtualDom.h)('span.admin-topics-results#topic-search-result', {
            title: attrs.topics[i].id
          }, attrs.topics[i].fancy_title), (0, _virtualDom.h)('br'), (0, _virtualDom.h)('div.blurbDiv#blurbDiv', {
            title: attrs.topics[i].id
          }, [(0, _virtualDom.h)('span.blurb#blurb', [(0, _node.dateNode)(createdAt), ' - ', (0, _virtualDom.h)('span#blurbBody', {
            title: attrs.topics[i].id
          }, postBlurb)])])])));
        }
      }

      var searchOptions = [];

      if (userResults.length > 0) {
        searchOptions.push((0, _virtualDom.h)('div.admin-results', [(0, _virtualDom.h)('h3.header', "User Results"), (0, _virtualDom.h)('ul.results-list#results-list', userResults)]));
      }

      if (topicResults.length > 0) {
        searchOptions.push((0, _virtualDom.h)('div.admin-results', [(0, _virtualDom.h)('h3.header', "Topic Results"), (0, _virtualDom.h)('ul#topic-results-list', topicResults)]));
      }

      if (searchOptions.length > 0) {
        results.push((0, _virtualDom.h)('div.menu-panel.admin-search-panel', searchOptions));
      }

      $('.admin-search-panel').show();
      return results;
    },
    click: function click(e) {
      if (e.target.id === 'admin-search-user') {
        this.sendWidgetAction('updateUser', e.target.title);
        return;
      }

      if ($(e.target).is("img")) {
        var user = e.target.title;

        _url.default.routeTo('/u/' + user);
      }

      if (e.target.id === 'topic-search-row' || e.target.id === 'blurbDiv' || e.target.id === 'blurbBody' || e.target.id === 'blurb' || e.target.id === 'topic-search-result') {
        _url.default.routeTo('/t/' + e.target.title);

        return;
      }
    },
    buildAttributes: function buildAttributes(attrs) {
      if (attrs.maxWidth) {
        return {
          'data-max-width': attrs.maxWidth,
          'style': 'height: 0px;'
        };
      }
    }
  });
});
define("discourse/plugins/admin-search/discourse/widgets/admin-search-widget", ["discourse/widgets/widget", "discourse/lib/url", "discourse/lib/ajax", "virtual-dom"], function (_widget, _url, _ajax, _virtualDom) {
  "use strict";

  var keyTracking = null;
  (0, _widget.createWidget)('admin-search', {
    tagName: 'div.admin-search-container',
    buildKey: function buildKey(attrs) {
      return "admin-search-container";
    },
    defaultState: function defaultState() {
      this.retrieveStaff();
      return {
        users: [],
        topics: [],
        posts: [],
        searching: false,
        invalidSearch: false,
        hasRun: false,
        selectedUser: {},
        searchFilters: {},
        staffUsers: {}
      };
    },
    html: function html() {
      var contents = [];

      if (Discourse.User.currentProp('staff')) {
        contents.push((0, _virtualDom.h)('input.admin-search-input', {
          id: 'admin-search-input',
          placeholder: 'Search users and topics by assignment and solve status. DEFAULT: My unsolved assigned tickets'
        }));
        var isStaff = this.state.selectedUser.moderator || this.state.selectedUser.admin || typeof this.state.selectedUser.id === 'undefined';
        contents.push(this.attach('admin-search-options', {
          isStaff: false
        }));
        contents.push(this.attach('admin-menu-panel', {
          isStaff: isStaff
        }));
        contents.push(this.attach('admin-search-results', {
          users: this.state.users,
          topics: this.state.topics,
          posts: this.state.posts,
          searching: this.state.searching,
          invalidSearch: this.state.invalidSearch,
          hasRun: this.state.hasRun,
          maxWidth: 500
        }));
        return contents;
      }
    },
    keyDown: function keyDown(e) {
      if (e.target.id === 'admin-search-input') {
        this.state.userResults = {};
        this.updateSearchState(true, false, false);
      }
    },
    keyUp: function keyUp(e) {
      var _this = this;

      if (e.which !== 13) {
        Ember.run.cancel(keyTracking);
        this.doneTyping();
      } else {
        if ($('#admin-search-input').val().length > 0) {
          Discourse.User.findByUsername($('#admin-search-input').val()).then(function (response) {
            if (response.admin || response.moderator) {
              _this.state.selectedUser["id"] = response.username;
              _this.state.searchFilters["assigned"] = true;
              _this.state.searchFilters["unassigned"] = false;

              _this.buildUrl();

              return;
            } else {
              _this.state.selectedUser = response;

              _this.buildUserUrl(true, $('#admin-search-input').val());

              return;
            }
          }).catch(function (err) {
            _this.buildUserUrl(false, $('#admin-search-input').val());

            return;
          });
        } else {
          if (typeof this.state.selectedUser.id === 'undefined') {
            this.updateSearchState(false, false, false);

            _url.default.routeTo('/latest?assigned=me&solved=no');

            return;
          }

          if (this.state.selectedUser.staff || this.state.selectedUser.admin) {
            this.buildUrl();
          } else {
            this.buildUserUrl(true);
          }
        }
      }
    },
    doneTyping: function doneTyping() {
      var _this2 = this;

      //this is needed because keyUp triggers too many times if a user types
      //quickly.
      keyTracking = Ember.run.later(function () {
        var searchTerm = $('#admin-search-input').val(); //Searching by topic id

        if (parseInt(searchTerm)) {
          _this2.updateSearchState(false, false, true);

          (0, _ajax.ajax)("/t/".concat(searchTerm, ".json")).then(function (response) {
            _this2.state.topics = [];
            _this2.state.posts = [];

            _this2.state.topics.push(response);

            _this2.state.posts.push(response.post_stream.posts);

            _this2.scheduleRerender();

            _this2.scheduleRerender();
          }).catch(function (error) {
            _this2.state.topics = {};
            _this2.state.users = {};

            _this2.scheduleRerender();
          });
        } else {
          if (typeof searchTerm !== 'undefined') {
            if (searchTerm.length === 0) {
              _this2.updateSearchState(false, false, false);

              _this2.scheduleRerender();

              return;
            }

            if (searchTerm.length < 3) {
              _this2.updateSearchState(false, true, false);

              _this2.scheduleRerender();

              return;
            }

            if (searchTerm.length > 2) {
              _this2.updateSearchState(false, false, true);

              (0, _ajax.ajax)("/admin/users/list/active.json?filter=".concat(searchTerm, "&show_emails=true")).then(function (response) {
                _this2.state.users = response;
                (0, _ajax.ajax)("/search/query?term=".concat(searchTerm, "&include_blurbs=true")).then(function (response) {
                  _this2.state.topics = response.topics;
                  _this2.state.posts = response.posts;

                  _this2.scheduleRerender();
                });
              });

              _this2.scheduleRerender();
            }
          }
        }
      }, 300);
    },
    updateSearchState: function updateSearchState(searching, invalid, hasRun) {
      this.state.searching = searching;
      this.state.invalidSearch = invalid;
      this.state.hasRun = hasRun;
      this.state.users = {};
      this.state.topics = {};
      this.state.posts = {};
    },
    clickOutside: function clickOutside() {
      $('.admin-search-panel').css({
        'display': 'none'
      });
      $('.admin-menu-panel').hide();
      this.updateSearchState(false, false, false);
    },
    click: function click() {
      if ($('.admin-menu-panel').is(':visible')) {
        $('.admin-menu-panel').hide();
      }

      this.state.users = {};
      this.state.topics = {};
      this.state.posts = [];
      Ember.run.cancel(keyTracking);
      this.doneTyping();
      this.scheduleRerender();
    },
    updateUser: function updateUser(args) {
      var _this3 = this;

      Discourse.User.findByUsername(args).then(function (response) {
        $('#admin-search-input').val(response.username);

        _this3.updateSearchState(false, false, false);

        _this3.state.selectedUser = response;
        $('.admin-search-panel').hide();
      });
    },
    updateSearchFilters: function updateSearchFilters(args) {
      this.state.searchFilters = args;
    },
    redirectSearch: function redirectSearch() {
      if (this.state.selectedUser.moderator || this.state.selectedUser.admin || typeof this.state.selectedUser.id === 'undefined') {
        this.buildUrl();
      } else {
        this.buildUserUrl(true);
      }
    },
    buildUserUrl: function buildUserUrl(userSearch, searchTerm) {
      var route = "";
      var assigned = "";
      var solved = "";
      var max_posts = "";
      var order = "";

      if (this.state.searchFilters.solved && !this.state.searchFilters.unsolved) {
        solved = ' in:solved ';
      } else if (!this.state.searchFilters.solved && this.state.searchFilters.unsolved) {
        solved = ' in:unsolved ';
      }

      if (this.state.searchFilters.unanswered) {
        max_posts = ' status:no_replies ';
      }

      var search = "";

      if (searchTerm !== "") {
        search = searchTerm;
      } else {
        search = this.state.selectedUser.username;
      }

      if (userSearch) {
        route = "/search?q=%40".concat(search).concat(solved).concat(max_posts);

        _url.default.routeTo(route);

        return;
      } else {
        route = "/search?q=".concat(searchTerm).concat(solved).concat(max_posts);

        _url.default.routeTo(route);

        return;
      }
    },
    buildUrl: function buildUrl() {
      var route = "";
      var assigned = "";
      var solved = "";
      var max_posts = "";
      var order = "";
      var solve_state = "";

      if (this.state.searchFilters.all) {
        route = '/latest';

        _url.default.handleURL(route);

        this.updateSearchState(false, false, false);
        this.scheduleRerender();
        return;
      }
      /*
      Administrative search filters.
      */


      if (this.state.searchFilters.assigned && !this.state.searchFilters.unassigned) {
        if (typeof this.state.selectedUser.id === 'undefined') {
          assigned = 'me';
        } else {
          assigned = this.state.selectedUser.username;
        }
      } else if (this.state.searchFilters.unassigned && !this.state.searchFilters.assigned || this.state.searchFilters.assigned && this.state.searchFilters.unassigned) {
        assigned = '';
      }

      if (this.state.searchFilters.solved && this.state.searchFilters.unsolved) {
        solved = '';
      } else if (this.state.searchFilters.solved && !this.state.searchFilters.unsolved) {
        solved = 'yes';
      } else if (this.state.searchFilters.unsolved && !this.state.searchFilters.solved) {
        solved = 'no';
      }

      if (this.state.searchFilters.unanswered) {
        max_posts = 1;
      }

      route = "/latest?assigned=".concat(assigned, "&solved=").concat(solved, "&max_posts=").concat(max_posts, "&order=activity&ascending=").concat(order);

      _url.default.routeTo(route);

      this.updateSearchState(false, false, false);
      this.scheduleRerender();
    },
    retrieveStaff: function retrieveStaff() {
      var _this4 = this;

      var staff = {};
      (0, _ajax.ajax)('/admin/users/list/staff.json').then(function (response) {
        _this4.state.staffUsers = response;
      });
    }
  });
});
define("discourse/plugins/admin-search/discourse/widgets/options-widget", ["discourse/widgets/widget", "discourse/lib/ajax", "virtual-dom"], function (_widget, _ajax, _virtualDom) {
  "use strict";

  /*
   Create the actual options text widget. This is the text that
   appears at the far right of the element.
  */
  (0, _widget.createWidget)('admin-search-options', {
    tagName: 'span.admin-search-options',
    buildKey: function buildKey(attrs) {
      return "admin-search";
    },
    defaultState: function defaultState() {
      return {
        menuCollapsed: false
      };
    },
    html: function html(attrs) {
      var contents = [];
      contents.push((0, _virtualDom.h)('span.admin-options', [(0, _virtualDom.h)('i.fa.fa-filter'), (0, _virtualDom.h)('b', 'Options')]));
      return contents;
    },
    click: function click() {
      if ($('.admin-menu-panel').is(':visible')) {
        $('.admin-menu-panel').hide();
      } else {
        $('.admin-menu-panel').show();
      }
    }
  });
});

